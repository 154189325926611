var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "content-right"
  }, [_c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "form-title"
  }, [_vm._v("投诉建议")]), _c('section', {
    staticClass: "form-box"
  }, [_c('div', {
    staticClass: "buttons"
  }, [_c('span', {
    class: {
      active: !_vm.isShow
    },
    on: {
      "click": _vm.handleIsShow
    }
  }, [_vm._v("投诉建议")]), _c('span', {
    class: {
      active: _vm.isShow
    },
    on: {
      "click": _vm.handleHistory
    }
  }, [_vm._v("历史记录")]), _c('div')]), (_vm.isShow === false ? true : false) ? _c('section', {
    staticClass: "fields"
  }, [_c('b-field', {
    attrs: {
      "label": "建议类别:",
      "type": _vm.danger ? 'is-danger' : '',
      "message": _vm.dangerMessage ? '不能为空' : ''
    }
  }, [_c('b-select', {
    attrs: {
      "placeholder": "请选择"
    },
    on: {
      "blur": _vm.blurDanger
    },
    model: {
      value: _vm.info.Category,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "Category", $$v);
      },
      expression: "info.Category"
    }
  }, _vm._l(_vm.CategoryList, function (item) {
    return _c('option', {
      key: item.id,
      domProps: {
        "value": item.suggestName
      }
    }, [_vm._v(" " + _vm._s(item.suggestName) + " ")]);
  }), 0)], 1), _c('b-field', {
    attrs: {
      "label": "内容描述:",
      "type": _vm.describe ? 'is-danger' : '',
      "message": _vm.textMessage ? '不能为空' : ''
    }
  }, [_c('b-input', {
    attrs: {
      "type": "textarea",
      "maxlength": "200"
    },
    on: {
      "blur": _vm.blurText
    },
    model: {
      value: _vm.info.text,
      callback: function callback($$v) {
        _vm.$set(_vm.info, "text", $$v);
      },
      expression: "info.text"
    }
  })], 1), _c('div', {
    staticClass: "control"
  }, [_c('b-button', {
    attrs: {
      "type": "is-light"
    },
    on: {
      "click": _vm.handleCancel
    }
  }, [_vm._v("取消")]), _c('b-button', {
    attrs: {
      "type": "is-info"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v("提交")])], 1)], 1) : _vm._e(), _vm.isShow ? _c('section', {
    staticClass: "history"
  }, [_vm._l(_vm.data, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "historyList"
    }, [_c('div', {
      staticClass: "history-title"
    }, [_c('div', {
      staticClass: "history-ti"
    }, [_c('span', [_vm._v(_vm._s(_vm._f("speName")(item.suggestStatus)))]), _c('span', [_vm._v(_vm._s(item.createdAt))])]), (item.replyStatus === 0 ? true : false) ? _c('span', {
      staticClass: "orange"
    }, [_vm._v("未回复")]) : _c('span', {
      staticClass: "gray"
    }, [_vm._v("已回复")])]), _c('div', {
      staticClass: "history-content"
    }, [_c('p', [_vm._v(_vm._s(item.suggestContent))]), (item.replyStatus === 0 ? false : true) ? _c('div', {
      staticClass: "stance"
    }) : _vm._e()]), (item.replyStatus === 0 ? false : true) ? _c('div', {
      staticClass: "reply-title"
    }, [_c('div', {
      staticClass: "reply-ti"
    }, [_c('span', [_vm._v("平台回复")]), _c('span', [_vm._v(_vm._s(item.updatedAt))])])]) : _vm._e(), (item.replyStatus === 0 ? false : true) ? _c('div', {
      staticClass: "reply-content"
    }, [_c('p', [_vm._v(_vm._s(item.platformReply))])]) : _vm._e()]);
  }), _c('div', {
    staticClass: "pagination",
    staticStyle: {
      "width": "901px",
      "justify-content": "flex-end"
    }
  }, [_c('b-pagination', {
    staticClass: "is-centered",
    attrs: {
      "total": _vm.total,
      "current": _vm.current,
      "per-page": _vm.perPage,
      "range-before": _vm.rangeBefore,
      "range-after": _vm.rangeAfter
    },
    on: {
      "update:current": function updateCurrent($event) {
        _vm.current = $event;
      },
      "change": _vm.handleChange
    }
  })], 1)], 2) : _vm._e()])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };